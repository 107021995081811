const patchPutAway = (app) => {
    const pageName = 'receiveInventoryPutAwayMain';
    const pageRoute = app.routes.getItem(pageName);
    if (pageRoute) {
        const routeProps = pageRoute.props;
        pageRoute.props = () => ({
            ...routeProps(),
            manualQuantity: false,
        });

        app.routes.changeItem(pageName, pageRoute);
    }
}

const patchShipmentGroupPicking = (app) => {
    const pageName = 'fulfillmentShipGroupPicking';
    const pageRoute = app.routes.getItem(pageName);
    if (pageRoute) {
        const routeProps = pageRoute.props;
        pageRoute.props = () => ({
            ...routeProps(),
            scanQuantityConfirmation: false,
            showCounterButtons: true,
        });

        app.routes.changeItem(pageName, pageRoute);
    }
}

// Patch DEV-6291 can be removed when 32.0.0> core update happen
export default {
    name: 'patchDEV6291',
    before: [],
    after: [],
    setup: (app) => {
        patchPutAway(app)
        patchShipmentGroupPicking(app);
    },
};